/* ModernUI, heavily based on Kepstin's wonderful CSS work
    https://github.com/kepstin/0x40hues-html5/blob/master/hues-m.css */

.hues-m-beatbar, .hues-m-beatcenter{
    transform: translateY(0px);
    transition: transform 1s ease-out;
}

.hues-m-controls {
    transform: translateY(0px);
    transition: transform 1s ease-out;
}

.hues-m-beatbar.hues-ui--hidden, .hues-m-beatcenter.hues-ui--hidden{
    transform: translateY(-40px);
}

.hues-m-controls.hues-ui--hidden {
    transform: translateY(108px);
}

.hues-m-visualisercontainer {
    position: absolute;
    width: 100%;
    height: 64px;
    bottom: 108px;
    left: -8px;
    right: 0;
    margin: 0 auto;
}

.hues-m-beatbar {
    position: absolute;
    top: 0;
    max-width: 992px;
    height: 30px;
    margin: 0 auto;
    overflow: hidden;
    left: 8px;
    right: 8px;
    color: white;
    background: rgba(127,127,127,0.5);
    border-color: rgba(0,0,0,0.5);
    border-width: 0 4px 4px;
    border-style: solid;
}
.inverted .hues-m-beatbar {
    border-color: rgba(255,255,255,0.5);
}

.hues-m-beatleft, .hues-m-beatright, .hues-m-songtitle, .hues-m-imagename, .hues-m-huename {
    color: white;
    background: rgba(0,0,0,0.7);
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 10px;
}
.inverted .hues-m-beatleft, .inverted .hues-m-beatright,
.inverted .hues-m-songtitle, .inverted .hues-m-imagename, .inverted .hues-m-huename {
    color: black;
    background: rgba(255,255,255,0.7);
}

.hues-m-leftinfo, .hues-m-rightinfo {
    position: absolute;
    font-size: 10px;
    text-align: center;
    color: rgba(255,255,255, 0.7);
    bottom: 79px;
    width: 100px;
}
.inverted .hues-m-leftinfo, .inverted .hues-m-rightinfo {
    color: rgba(0,0,0, 0.7);
}

.hues-m-leftinfo {
    left: 8px;
}

.hues-m-rightinfo {
    right: 8px;
}

.hues-m-huename {
    font-size: 8px;
    height: 12px;
    line-height: 12px;
    border-radius: 10px;
}

.hues-m-beatleft, .hues-m-beatright {
    position: absolute;
    padding: 0 0 0 20px;
    top: 5px;
    overflow: hidden;
    border-radius: 0 10px 10px 0;
}
.hues-m-beatleft {
    transform: scaleX(-1);
    left: 8px;
    right: 50%;
}
.hues-m-beatright {
    left: 50%;
    right: 8px;
}

.hues-m-beatcenter {
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 40px;
    width: 40px;
    color: white;
    background: #505050;
    font-size: 20px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    box-shadow: inset 0 0 12px rgba(0,0,0,0.5);
}
.inverted .hues-m-beatcenter {
    color: black;
    background: #afafaf;
    box-shadow: inset 0 0 12px rgba(255,255,255,0.5);
}

.hues-m-beatcenter > span {
    animation-duration: 150ms;
    animation-name: hues-m-beatcenter;
    animation-fill-mode: forwards;
}
@keyframes hues-m-beatcenter {
    from {
    opacity: 1;
    }
    50% {
    opacity: 1;
    }
    to {
    opacity: 0;
    }
}

.hues-m-controls {
    position: absolute;
    bottom: 0;
    max-width: 992px;
    height: 104px;
    margin: 0 auto;
    left: 8px;
    right: 8px;
    color: rgba(255,255,255,0.7);
    background: rgba(127,127,127,0.5);
    border-color: rgba(0,0,0,0.5);
    border-width: 4px 4px 0;
    border-style: solid;
}
.inverted .hues-m-controls {
    color: rgba(0,0,0,0.7);
    border-color: rgba(255,255,255,0.5);
}

.hues-m-songtitle, .hues-m-imagename, .hues-m-huename {
    position: absolute;
    text-align: center;
    padding: 0 4px;
    left: 8px;
    right: 8px;
}
.hues-m-songtitle {
    bottom: 55px;
}
.hues-m-imagename {
    bottom: 79px;
    left: 108px;
    right: 108px;
}

.hues-m-songtitle > a:link, .hues-m-songtitle > a:visited, .hues-m-imagename > a:link, .hues-m-imagename > a:visited {
    color: inherit;
    text-decoration: none;
}

.hues-m-songtitle > a.small, .hues-m-imagename > a.small {
    font-size: 10px;
}

.hues-m-songtitle > a.x-small, .hues-m-imagename > a.x-small {
    font-size: 8px;
}

.hues-m-leftbox {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 50%;
    height: 54px;
}

.hues-m-rightbox {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    height: 54px;
}

.hues-m-controlblock {
    /* Don't want double click to select */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    width: 50%;
    height: 100%;
    margin: 3px auto;
    float: left;
    position: relative;
}

.hues-m-controlbuttons {
    margin: auto;
    position: relative;
    width: 70px;
}

.hues-m-songbutton {
    cursor: pointer;
    text-align: center;
}

.hues-m-prevbutton, .hues-m-nextbutton, .hues-m-actbutton {
    position: absolute;
    cursor: pointer;
}

.hues-m-prevbutton:hover, .hues-m-nextbutton:hover, .hues-m-actbutton:hover {
    background: #646464;
}
.inverted .hues-m-prevbutton:hover,
.inverted .hues-m-nextbutton:hover,
.inverted .hues-m-actbutton:hover {
    background: #9b9b9b;
}

.hues-m-prevbutton, .hues-m-nextbutton {
    color: white;
    background: #282828;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 10px;
    top: 7.5px;
}
.inverted .hues-m-prevbutton, .inverted .hues-m-nextbutton {
    color: black;
    background: #D7D7D7;
}

.hues-m-prevbutton {
    padding: 0 10px 0 0;
    left: 5px;
    border-radius: 10px 0 0 10px;
}

.hues-m-nextbutton {
    padding: 0 0 0 10px;
    left: 42px;
    border-radius: 0 10px 10px 0;
}

.hues-m-actbutton {
    height: 35px;
    width: 35px;
    left: 17.5px;
    color: white;
    background: #202020;
    font-size: 20px;
    line-height: 35px;
    border-radius: 20px;
    text-align: center;
    z-index: 1;
}
.inverted .hues-m-actbutton {
    color: black;
    background: #DFDFDF;
}

.hues-m-huename {
    bottom: 5px;
}

.hues-m-question, .hues-m-cog, .hues-m-hide {
    cursor: pointer;
}

.hues-m-cog {
    position: absolute;
    left: 14px;
    top: 1px;
    font-size: 20px;
}

.hues-m-hide {
    position: absolute;
    left: 15px;
    top: 22px;
    font-size: 15px;
}

.hues-m-hiderestore {
    display: none;
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 0;
    margin: 0 auto;
    height: 30px;
    max-width: 992px;
    background: rgba(0,0,0,0);
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    cursor: pointer;
}

.hues-m-hiderestore:hover {
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.4));
}
.inverted .hues-m-hiderestore:hover {
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.4));
}

.hues-m-hiderestore.hues-ui--hidden {
    display: block;
}

.hues-m-question {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 25px;
}

.hues-m-vol-bar {
    position: absolute;
    height: 20px;
    bottom: 21px;
    left: 40px;
    right: 40px;
}

.hues-m-vol-label {
    display: block;
    position: absolute;
    left: 0;
    bottom: 14px;
    right: 0;
    height: 12px;
    color: inherit;
    font: inherit;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    padding: 0;
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
}
.hues-m-vol-bar > input {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 12px;
}

.hues-m-listcontainer {
    position: absolute;
    right: 8px;
    bottom: 110px;
    z-index: 1; /* put it in front of xmas UI lights */
}

/* Fun slider stuff! */

input[type=range] {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 12px;
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    background: rgba(255,255,255,0.7);
    border: none;
    border-radius: 0;
}
.inverted input[type=range]::-webkit-slider-runnable-track {
    background: rgba(0,0,0,0.7);
}

input[type=range]::-webkit-slider-thumb {
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    height: 12px;
    width: 4px;
    border-radius: 0;
    background: white;
    margin-top: -4px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}
.inverted input[type=range]::-webkit-slider-thumb {
    background: white;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    background: rgba(255,255,255,0.7);
    border: none;
    border-radius: 0;
}
.inverted input[type=range]::-moz-range-track {
    background: rgba(0,0,0,0.7);
}

input[type=range]::-moz-range-thumb {
    box-shadow: none;
    border: none;
    height: 12px;
    width: 4px;
    border-radius: 0;
    background: white;
}
.inverted input[type=range]::-moz-range-thumb {
    background: black;
}

input[type=range]::-ms-track {
    width: 100%;
    background: transparent; /* Hides the slider so custom styles can be added */
    border-color: transparent;
    color: transparent;
    height: 4px;
    border-width: 4px 0;
}

input[type=range]::-ms-fill-lower {
    background: rgba(255,255,255,0.7);
}
.inverted input[type=range]::-ms-fill-lower {
    background: rgba(0,0,0,0.7);
}
input[type=range]::-ms-fill-upper {
    background: rgba(0,0,0,0.7);
}
.inverted input[type=range]::-ms-fill-upper {
    background: rgba(255,255,255,0.7);
}

input[type=range]::-ms-thumb {
    box-shadow: none;
    border: none;
    height: 12px;
    width: 4px;
    border-radius: 0;
    background: white;
}
.inverted input[type=range]::-ms-thumb {
    background: black;
}

@media (min-width: 768px) {
  .hues-m-controls {
    height: 54px;
    }
  .hues-m-controls.hues-ui--hidden {
    transform: translateY(58px);
  }
  .hues-m-imagename {
    left: 300px;
    right: 300px;
    bottom: 29px;
  }
  .hues-m-songtitle {
    left: 192px;
    right: 192px;
    bottom: 5px;
  }
  .hues-m-leftinfo {
    left: 200px;
  }
  .hues-m-rightinfo {
    right: 200px;
  }
  .hues-m-leftinfo, .hues-m-rightinfo {
    bottom: 29px;
  }
  .hues-m-leftbox {
    left: 0;
    right: auto;
    width: 192px;
    height: 54px;
    }
  .hues-m-rightbox {
    left: auto;
    right: 0;
    width: 192px;
    height: 54px;
    }
  .hues-m-listcontainer {
    bottom: 60px;
    max-width: 992px;
    margin: 0 auto;
    left: 8px;
    }
  .hues-m-visualisercontainer {
    bottom:58px;
  }
}
