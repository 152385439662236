/* HalloweenUI */

.hues-h-text  {
    color: #ff3300;
}
.inverted .hues-h-text {
    color: #00ccff;
}

.hues-preloader.hues-h-text {
    background: linear-gradient(to right, #000 0%,#000 50%,#222 50%,#222 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
}

.hues-h-textfade {
    color: rgba(255, 51, 0, 0.6);
}
.inverted .hues-h-textfade {
    color: rgba(0, 204, 255, 0.6);
}

.hues-m-beatbar.hues-h-beatbar {
    border-style: none;
    background: none;
    overflow: inherit;
}

.hues-m-beatcenter.hues-h-text {
    background: none;
    background-image: url('../../img/skull.png');
    top: 0;
    width: 42px;
    height: 43px;
    box-shadow: none;
    padding-top: 21px;
}
.inverted .hues-m-beatcenter.hues-h-text {
    background-position: -42px 0;
}

.hues-m-beatcenter.hues-h-text > span {
    font-size: 13px;
}

.hues-m-beatcenter.hues-h-text.hues-ui--hidden{
    transform: translateY(-80px);
}

.hues-h-eyes {
    background: none;
    background-image: url('../../img/skull-eyes.png');
    top: 0;
    width: 42px;
    height: 64px;
    box-shadow: none;

    animation-duration: 150ms;
    animation-name: hues-m-beatcenter;
    animation-fill-mode: forwards;
}
.inverted .hues-h-eyes {
    /* Set again to override the other .inverted selector from modern */
    background: none;
    background-image: url('../../img/skull-eyes.png');
    box-shadow: none;
    background-position: -42px 0;
}

.hues-h-left-hand {
    background: url('../../img/left-hand.png');
    left: -15px;
}

.hues-h-right-hand {
    background: url('../../img/right-hand.png');
    right: -15px;
}

.hues-h-left-hand, .hues-h-right-hand {
    width: 63px;
    height: 42px;
    position: absolute;
    background-repeat: no-repeat;
}
.inverted .hues-h-left-hand, .inverted  .hues-h-right-hand {
    background-position: -63px 0;
}

.hues-m-controls.hues-h-controls {
    background: none;
    border-style: none;
    padding-top: 8px;
}

@media (min-width: 768px) {
  .hues-m-controls.hues-h-controls.hues-ui--hidden {
    transform: translateY(64px);
  }
}

.hues-m-songtitle.hues-h-text, .hues-m-imagename.hues-h-text {
    padding: 4px 0;
    margin: 0 5px;
    background: none;
    border-style: solid;
    border-width: 0 19px 0 18px;
    border-image: url(../../img/bones.png) 29 19 0 18 fill repeat stretch;
}
.inverted .hues-m-songtitle.hues-h-text, .inverted .hues-m-imagename.hues-h-text {
    border-image-slice: 0 19 29 18 fill;
}

.hues-m-huename.hues-h-text {
    border: none;
    background: none;
    left: 38px;
    right: 38px;
    bottom: 2px;
}

.hues-m-vol-bar.hues-h-vol-bar {
    bottom: 13px;
}

.hues-m-vol-label.hues-h-text {
    bottom: 12px;
}

.hues-m-hide.hues-h-text {
    top: 40px;
}

.hues-m-cog.hues-h-text {
    top: 18px;
}

.hues-m-question.hues-h-text {
    top: 25px;
}

.hues-m-songbutton.hues-h-text, .hues-m-imagebutton.hues-h-text {
    margin-top: 17px;
}

.hues-m-songbutton.hues-h-text + div, .hues-m-imagebutton.hues-h-text + div{
    top: -8px;
}

.hues-m-prevbutton.hues-h-text, .hues-m-nextbutton.hues-h-text, .hues-m-actbutton.hues-h-text {
    background: none;
}

.hues-h-controls > .hues-m-leftinfo, .hues-h-controls > .hues-m-rightinfo {
    margin-bottom: 5px;
}

.hues-h-tombstone {
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -10;

    border-style: solid;
    border-width: 22px 40px 0 42px;
    border-image: url(../../img/tombstone.png) 22 42 0 fill stretch;
}
.inverted .hues-h-tombstone {
    border-image: url(../../img/tombstone_invert.png) 22 42 0 fill stretch;
}

.hues-h-text + input[type=range]::-webkit-slider-runnable-track {
    background: #ff3300;
}
.inverted .hues-h-text + input[type=range]::-webkit-slider-runnable-track {
    background: #00ccff;
}

.hues-h-text + input[type=range]::-webkit-slider-thumb {
    background: #ff3300;
}
.inverted .hues-h-text + input[type=range]::-webkit-slider-thumb {
    background: #00ccff;
}

.hues-h-text + input[type=range]::-moz-range-track {
    background: #ff3300;
}
.inverted .hues-h-text + input[type=range]::-moz-range-track {
    background: #00ccff;
}

.hues-h-text + input[type=range]::-moz-range-thumb {
    background: #ff3300;
}
.inverted .hues-h-text + input[type=range]::-moz-range-thumb {
    background: #00ccff;
}

.hues-h-text + input[type=range]::-ms-fill-lower {
    background: #ff3300;
}
.inverted .hues-h-text + input[type=range]::-ms-fill-lower {
    background: #00ccff;
}
.hues-h-text + input[type=range]::-ms-thumb {
    background: #ff3300;
}
.inverted .hues-h-text + input[type=range]::-ms-thumb {
    background: #00ccff;
}

.hues-h-topleft, .hues-h-topright, .hues-h-bottomright {
    position: absolute;
    background-repeat: no-repeat;
    z-index: -9;
}

.hues-h-topleft, .hues-h-topright {
    top: 0;
}

.hues-h-bottomright, .hues-h-topright {
    right: 0;
}

.hues-h-topleft {
    background-image: url("../../img/web-topleft.png");
    width: 269px;
    height: 237px;
}
.inverted .hues-h-topleft {
    background-position: -269px 0;
}

.hues-h-topright {
    background-image: url("../../img/web-topright.png");
    width: 215px;
    height: 220px;
}
.inverted .hues-h-topright {
    background-position: -215px 0;
}

.hues-h-bottomright {
    background-image: url("../../img/web-bottomright.png");
    bottom: 0;
    width:358px;
    height: 284px;
}
.inverted .hues-h-bottomright {
    background-position: -358px 0;
}

.hues-h-vignette {
    background-image: url("../../img/vignette.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
